import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectAdsReady} from 'app/components/ads/AdCore';
import {updateAdsReady} from 'app/actions/sessionActions';
import {selectAdMonetizer} from 'app/hooks/reduxCreateSelectorHooks';
import {captureSentryException} from 'app/services/sentryLogging';
import {ConsentStatus, selectConsentStatus} from 'app/helpers/consent';
import {sendEventToGA4} from 'app/helpers/gtagHelpers';
import {markAdServerReady} from 'app/services/performance/PerformanceMarks';

const scriptSrc = {
  qm: '/ads/qmax_min.js?v33',
};

const initAdScript = adMonetizer => {
  const script = document.createElement('script');
  script.src = scriptSrc[adMonetizer];
  script.async = true;
  script.type = 'text/javascript';
  document.head.appendChild(script);
};

const useInitAdSetup = () => {
  // Skip for SSR
  if (typeof window === 'undefined') {
    return;
  }

  const adMonetizer = useSelector(selectAdMonetizer);
  const adsReady = useSelector(selectAdsReady);
  const consentStatus = useSelector(selectConsentStatus);
  const isTcLoaded = consentStatus === ConsentStatus.TCLOADED;
  const isUserActionCompleted = consentStatus === ConsentStatus.USERACTIONCOMPLETE;
  const dispatch = useDispatch();
  const isQM = adMonetizer === 'qm';
  const startTimeForTCLoadedOrUserActionComplete = useRef(null);
  const startTimeForTCLoaded = useRef(null);

  useEffect(() => {
    if ((isTcLoaded || isUserActionCompleted) && !startTimeForTCLoadedOrUserActionComplete.current) {
      startTimeForTCLoadedOrUserActionComplete.current = window.performance.now();
    }

    const handleVisibilityChange = () => {
      if (!adsReady && (isTcLoaded || isUserActionCompleted)) {
        if (document.visibilityState === 'hidden') {
          try {
            if (!startTimeForTCLoadedOrUserActionComplete.current) {
              throw new Error('startTimeForTCLoadedOrUserActionComplete not set');
            }
            const elapsedTime = Math.round(window.performance.now() - startTimeForTCLoadedOrUserActionComplete.current);

            if (process.env.ENVIRONMENT !== 'prod') {
              console.log(`Session ended without adserver ready. Session duration: ${elapsedTime} ms`);
            } else {
              sendEventToGA4('adserver_never_starts_bidding', {
                session_duration_after_consent: elapsedTime,
                fupa_tech_metric: 'adserver_never_starts_bidding',
              });
            }
          } catch (error) {
            if (process.env.ENVIRONMENT !== 'prod') {
              console.error(`Could not send event for adserver_never_starts_bidding.`, error);
            } else {
              captureSentryException(error, {
                extra: {errorLocation: 'useInitAdSetup: Could not send event for adserver_never_starts_bidding'},
              });
            }
          }
        } else {
          // reset startTimeForTCLoadedOrUserActionComplete if tab changes multiple times visibility
          startTimeForTCLoadedOrUserActionComplete.current = window.performance.now();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [adsReady, isTcLoaded, isUserActionCompleted]);

  useEffect(() => {
    // Only for QM Setup
    // Need to listen for namespace `window.qm_adserver_ready` to set flag `adsReady` to trigger Ads
    if (!adsReady && isQM) {
      if (isTcLoaded && !startTimeForTCLoaded.current) {
        startTimeForTCLoaded.current = window.performance.now();
      }
      const checkForQMReady = setInterval(() => {
        if (window.qm_adserver_ready) {
          markAdServerReady();
          clearInterval(checkForQMReady);
          if (startTimeForTCLoaded.current) {
            const elapsedTime = Math.round(window.performance.now() - startTimeForTCLoaded.current);
            if (elapsedTime < 0) {
              captureSentryException(new Error('Range Error: Negative elapsed time detected'), {
                extra: {elapsedTime, startTimeForTCLoaded: startTimeForTCLoaded.current},
              });
            }
            if (process.env.ENVIRONMENT !== 'prod') {
              console.log(`Time taken for adsReady: ${elapsedTime} ms`);
            } else {
              sendEventToGA4('adserver_init_duration', {
                time_spent: elapsedTime,
                fupa_tech_metric: 'adserver_init_duration',
              });
            }
          }
          dispatch(updateAdsReady(true));
        }
      }, 50);

      return () => {
        clearInterval(checkForQMReady);
      };
    }
  }, [adsReady, isTcLoaded]);

  useEffect(() => {
    window.addEventListener('tcfError', function (event) {
      const additionalTag = {event_status: event.detail?.eventStatus};
      const hasValidTCData = event.detail?.eventStatus === 'cmpuishown';
      if (!hasValidTCData) {
        captureSentryException(
          new Error('tcfError: TCF not loaded, invalid tcData'),
          {extra: event.detail},
          additionalTag
        );
      }
    });
  }, []);

  useEffect(() => {
    if (adMonetizer) {
      initAdScript(adMonetizer);
    }
  }, [adMonetizer]);
};

export {useInitAdSetup};
