import {EPerformanceMark} from 'app/services/performance/EPerformance';

const markHydrationCompleted = () => {
  window.performance.mark(EPerformanceMark.HYDRATIONCOMPLETED, {
    detail: {
      devtools: {
        dataType: 'marker',
        properties: [['duration', performance.now().toString()]],
      },
    },
  });
};

const markTcfApiReady = () => {
  window.performance.mark(EPerformanceMark.TCFAPIREADY, {
    detail: {
      devtools: {
        dataType: 'marker',
      },
    },
  });
};

const markTcDataEventStatus = eventStatus => {
  window.performance.mark(EPerformanceMark.TCDATAEVENTSTATUS, {
    detail: {
      eventStatus: eventStatus,
      devtools: {
        dataType: 'marker',
      },
    },
  });
};

const markAdServerReady = () => {
  window.performance.mark(EPerformanceMark.ADSERVERREADY, {
    detail: {
      devtools: {
        dataType: 'marker',
      },
    },
  });
};

const markAdServerEvent = (markName: string, slotId: string) => {
  window.performance.mark(markName, {
    detail: {
      slotId: slotId,
      devtools: {
        dataType: 'marker',
        tooltipText: `${markName}:${slotId}`,
        properties: [['slotId', slotId]],
      },
    },
  });
};

export {markHydrationCompleted, markTcfApiReady, markTcDataEventStatus, markAdServerReady, markAdServerEvent};
