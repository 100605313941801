import {useInitAdSetup} from 'app/components/ads/useInitAdSetup';
import {useAdDefaultTargeting} from 'app/components/ads/useAdTargeting';
import {useParallax} from 'app/components/ads/useParallax';
import usePPID from 'app/components/ads/usePPID';
import {useEffect} from 'react';
import useSkipSkyscraper from 'app/components/ads/useSkipSkyscraper';
import useWebInterstitial from 'app/components/ads/useWebInterstitial';
import useInitAdSlotPerformance from 'app/components/ads/useInitAdSlotPerformance';
// import * as Sentry from '@sentry/react';

const useAdServices = () => {
  // Init qmax and cmp script
  useInitAdSetup();
  // Set Ad default targeting
  useAdDefaultTargeting();
  // Create PPID if not existing
  usePPID();
  // Enable Parallax-Effect on Ads
  useParallax();
  // Disable Skyscraper if Skin is active on Superbanner
  useSkipSkyscraper();
  // Web Interstitial for mobile
  useWebInterstitial();
  // Mark performance for ad slot events first time
  useInitAdSlotPerformance();

  useEffect(() => {
    window.addEventListener('error', function (event) {
      // Get the error properties from the error event object
      const {message, error} = event;
      const sodErrorMessage = message === "Uncaught TypeError: Cannot read properties of null (reading 'postMessage')";

      const sodErrorStack = error?.stack?.includes('adaptPostScroll') && error?.stack?.includes('adaptBanners');
      if (sodErrorMessage && sodErrorStack) {
        // Sentry.captureMessage('SoD Skin caused page reload');
        window.location.reload();
      }
    });
  }, []);
};

export {useAdServices};
