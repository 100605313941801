import {useEffect, useRef, useState} from 'react';
import {EPerformanceMark} from 'app/services/performance/EPerformance';
import {measureAdSlotPerformance} from 'app/services/performance/PerformanceMeasurments';
import {markAdServerEvent} from 'app/services/performance/PerformanceMarks';

const disabledSlots = ['ad-Sponsor_1', 'web_interstitial'];

const adServerEventsToPerformanceMarkMapping = {
  slotRequested: EPerformanceMark.SLOTREQUESTED,
  slotResponseReceived: EPerformanceMark.SLOTRESPONSERECEIVED,
  slotOnload: EPerformanceMark.SLOTONLOAD,
  slotRenderEnded: EPerformanceMark.SLOTRENDERENDED,
};
const adServerEvents = Object.keys(adServerEventsToPerformanceMarkMapping);

const useInitAdSlotPerformance = () => {
  const initRef = Object.fromEntries(adServerEvents.map(eventName => [eventName, false]));
  const executedHandlersRef = useRef(initRef);

  const [allEventsTriggered, setAllEventsTriggered] = useState(false);
  const [handlersInitialized, setHandlersInitialized] = useState(false);
  const eventHandlersRef = useRef<Record<string, (event: any) => void>>({});

  useEffect(() => {
    eventHandlersRef.current = adServerEvents.reduce((handlers, eventName) => {
      handlers[eventName] = (event: any) => {
        const slot = event.slot;
        const slotId = slot.getSlotElementId();
        const disablePerformanceMarker = disabledSlots.find(disabledSlotsId => slotId.includes(disabledSlotsId));
        if (disablePerformanceMarker || executedHandlersRef.current[eventName]) {
          return;
        }

        markAdServerEvent(adServerEventsToPerformanceMarkMapping[eventName], slotId);

        executedHandlersRef.current = {...executedHandlersRef.current, [eventName]: true};
        if (Object.values(executedHandlersRef.current).every(value => value)) {
          setAllEventsTriggered(true);
        }
      };
      return handlers;
    }, {} as Record<string, (event: any) => void>);

    setHandlersInitialized(true);
  }, []);

  useEffect(() => {
    window.googletag.cmd.push(() => {
      adServerEvents.forEach(eventName => {
        const handler = eventHandlersRef.current[eventName];
        if (handler) {
          window.googletag.pubads().addEventListener(eventName, handler);
        }
      });
    });

    return () => {
      window.googletag.cmd.push(() => {
        adServerEvents.forEach(eventName => {
          const handler = eventHandlersRef.current[eventName];
          if (handler) {
            window.googletag.pubads().removeEventListener(eventName, handler);
          }
        });
      });
    };
  }, [handlersInitialized]);

  useEffect(() => {
    if (allEventsTriggered) {
      window.googletag.cmd.push(() => {
        adServerEvents.forEach(eventName => {
          const handler = eventHandlersRef.current[eventName];
          if (handler) {
            window.googletag.pubads().removeEventListener(eventName, handler);
          }
        });
      });
      measureAdSlotPerformance();
    }
  }, [allEventsTriggered]);
};

export default useInitAdSlotPerformance;
