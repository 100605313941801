import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {setDimensionToGA4} from 'app/helpers/gtagHelpers';
import {updateConsentStatus} from 'app/actions/sessionActions';
import {createSelector} from 'reselect';
import {markTcDataEventStatus, markTcfApiReady} from 'app/services/performance/PerformanceMarks';

const GoogleAdvertisingProductsVendorId = 755;
enum ConsentStatus {
  LOADING = 'loading',
  TCLOADED = 'tcloaded',
  USERACTIONCOMPLETE = 'useractioncomplete',
  CMPUISHOWN = 'cmpuishown',
}

interface SessionState {
  session: SessionPartialState;
}

interface SessionPartialState {
  consentStatus: ConsentStatus;
}
interface TCData {
  vendor?: {
    consents: {[key: number]: boolean};
  };
  purpose?: {
    consents: {[key: string]: boolean};
  };
  eventStatus?: string;
}

const updateGA4ConsentSettings = (tcData: TCData): void => {
  const result = window.__cmp('consentStatus');

  const analyticsStorageConsent = result?.userChoiceExists ? 'granted' : 'denied';
  const adStorageConsent = tcData?.vendor?.consents[GoogleAdvertisingProductsVendorId] ? 'granted' : 'denied';

  window.gtag('consent', 'update', {
    ad_user_data: adStorageConsent,
    ad_personalization: adStorageConsent,
    ad_storage: adStorageConsent,
    analytics_storage: analyticsStorageConsent,
  });
};

const useConsentCheckForPPID = (): boolean => {
  const [consentChecked, setConsentChecked] = useState(false);

  useEffect(() => {
    const checkForConsent = setInterval(function () {
      if (typeof window.__tcfapi === 'function') {
        window.clearInterval(checkForConsent);
        window.__tcfapi('addEventListener', 2, function (tcData: TCData) {
          if (tcData.vendor?.consents[GoogleAdvertisingProductsVendorId]) {
            setConsentChecked(true);
          }
        });
      }
    }, 50);
  }, []);

  return consentChecked;
};

const checkConsentForPurposeCollectData = (): boolean => {
  const result = window.__cmp('getCMPData');
  const collectDataPurposeId = '1';
  const consent = result?.purposeConsents[collectDataPurposeId];
  return Boolean(consent);
};

const sendConsentStatusToAnalytics = (tcData: TCData): void => {
  const countPurposeConsents = Object.keys(tcData.purpose?.consents || {}).length;
  const consent_status = countPurposeConsents === 10 ? 'approved' : countPurposeConsents > 0 ? 'custom' : 'rejected';
  setDimensionToGA4({user_properties: {fupa_consent_status: consent_status}});
};

const useConsent = (): void => {
  const dispatch = useDispatch();
  useEffect(() => {
    const checkForConsent = setInterval(function () {
      if (typeof window.__tcfapi === 'function') {
        markTcfApiReady();
        window.clearInterval(checkForConsent);
        window.__tcfapi('addEventListener', 2, function (tcData: TCData) {
          if (
            tcData.eventStatus === ConsentStatus.TCLOADED ||
            tcData.eventStatus === ConsentStatus.USERACTIONCOMPLETE
          ) {
            sendConsentStatusToAnalytics(tcData);
            updateGA4ConsentSettings(tcData);
          }
          markTcDataEventStatus(tcData?.eventStatus);
          dispatch(updateConsentStatus(tcData?.eventStatus));
        });
      }
    }, 50);
  }, []);
};

const selectConsentStatus = createSelector(
  (state: SessionState) => state.session,
  (session: SessionPartialState): ConsentStatus => session.consentStatus
);

export {ConsentStatus, useConsent, useConsentCheckForPPID, checkConsentForPurposeCollectData, selectConsentStatus};
