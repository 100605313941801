import {EPerformanceMark, EPerformanceMeasure} from 'app/services/performance/EPerformance';

const measureAdSlotPerformance = () => {
  window.performance.measure(EPerformanceMeasure.SLOTPROCESSDURATION, {
    detail: {
      devtools: {
        dataType: 'track-entry',
        track: 'Critical Path: Ads',
      },
    },
    start: EPerformanceMark.ADSERVERREADY,
    end: EPerformanceMark.SLOTRENDERENDED,
  });
  window.performance.measure(EPerformanceMeasure.SLOTBIDDINGDURATION, {
    detail: {
      devtools: {
        dataType: 'track-entry',
        track: 'Critical Path: Ads',
      },
    },
    start: EPerformanceMark.SLOTREQUESTED,
    end: EPerformanceMark.SLOTRESPONSERECEIVED,
  });
  window.performance.measure(EPerformanceMeasure.SLOTSTARTLOADDURATION, {
    detail: {
      devtools: {
        dataType: 'track-entry',
        track: 'Critical Path: Ads',
      },
    },
    start: EPerformanceMark.SLOTRESPONSERECEIVED,
    end: EPerformanceMark.SLOTONLOAD,
  });
  window.performance.measure(EPerformanceMeasure.SLOTRENDERINGDURATION, {
    detail: {
      devtools: {
        dataType: 'track-entry',
        track: 'Critical Path: Ads',
      },
    },
    start: EPerformanceMark.SLOTONLOAD,
    end: EPerformanceMark.SLOTRENDERENDED,
  });
};

const measureHydrationDuration = () => {
  performance.measure(EPerformanceMeasure.HYDRATIONDURATION, {
    detail: {
      devtools: {
        dataType: 'track-entry',
        track: 'Critical Path: Ads', // Group related tracks together
      },
    },
    end: EPerformanceMark.HYDRATIONCOMPLETED,
  });
};

export {measureAdSlotPerformance, measureHydrationDuration};
