import { useEffect, useRef } from 'react';
import { EPerformanceMark } from 'app/services/performance/EPerformance';
import { ConsentStatus } from 'app/helpers/consent';
import { sendEventToGA4 } from 'app/helpers/gtagHelpers';
import { captureSentryException } from 'app/services/sentryLogging';
const usePerformanceMeasurement = () => {
  const performanceTrackedRef = useRef(false);
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && !performanceTrackedRef.current) {
        performanceTrackedRef.current = true;
        try {
          var _window$performance$g, _window$performance$g2, _window$performance$g3, _window$performance$g4, _window$performance$g5, _window$performance$g6;
          const sessionWithSlotRendered = window.performance.getEntriesByName(EPerformanceMark.SLOTRENDERENDED)[0];
          const sessionWithTCLoaded = window.performance.getEntriesByName(EPerformanceMark.TCDATAEVENTSTATUS).find(mark => {
            var _mark$detail;
            return (mark === null || mark === void 0 || (_mark$detail = mark.detail) === null || _mark$detail === void 0 ? void 0 : _mark$detail.eventStatus) === ConsentStatus.TCLOADED;
          });
          if (!sessionWithSlotRendered || !sessionWithTCLoaded) {
            return;
          }
          const hydrationCompletedAt = (_window$performance$g = window.performance.getEntriesByName(EPerformanceMark.HYDRATIONCOMPLETED)[0]) === null || _window$performance$g === void 0 ? void 0 : _window$performance$g.startTime;
          const tcfApiReadyAt = (_window$performance$g2 = window.performance.getEntriesByName(EPerformanceMark.TCFAPIREADY)[0]) === null || _window$performance$g2 === void 0 ? void 0 : _window$performance$g2.startTime;
          const adServerReadyAt = (_window$performance$g3 = window.performance.getEntriesByName(EPerformanceMark.ADSERVERREADY)[0]) === null || _window$performance$g3 === void 0 ? void 0 : _window$performance$g3.startTime;
          const firstSlotRequestedAt = (_window$performance$g4 = window.performance.getEntriesByName(EPerformanceMark.SLOTREQUESTED)[0]) === null || _window$performance$g4 === void 0 ? void 0 : _window$performance$g4.startTime;
          const firstSlotResponseReceivedAt = (_window$performance$g5 = window.performance.getEntriesByName(EPerformanceMark.SLOTRESPONSERECEIVED)[0]) === null || _window$performance$g5 === void 0 ? void 0 : _window$performance$g5.startTime;
          const firstSlotOnLoadAt = (_window$performance$g6 = window.performance.getEntriesByName(EPerformanceMark.SLOTONLOAD)[0]) === null || _window$performance$g6 === void 0 ? void 0 : _window$performance$g6.startTime;
          sendEventToGA4('first_adslot_process', {
            hydrationCompletedAt,
            tcfApiReadyAt,
            tcLoadedAt: sessionWithTCLoaded.startTime,
            adServerReadyAt,
            firstSlotRequestedAt,
            firstSlotResponseReceivedAt,
            firstSlotOnLoadAt,
            firstSlotRenderEnded: sessionWithSlotRendered.startTime,
            fupa_tech_metric: 'first_adslot_process'
          });
        } catch (error) {
          if (process.env.ENVIRONMENT !== 'prod') {
            console.error("Could measure performance for \"first_adslot_process\"", error);
          } else {
            captureSentryException(error, {
              extra: {
                errorLocation: 'usePerformanceMeasurement: Could not send event for "first_adslot_process"'
              }
            });
          }
        }
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};
export default usePerformanceMeasurement;