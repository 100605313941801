const PREFIX = 'FuPa:';

export enum EPerformanceMark {
  HYDRATIONCOMPLETED = `${PREFIX}HydrationCompleted`,
  TCDATAEVENTSTATUS = `${PREFIX}TcDataEventStatus`,
  TCFAPIREADY = `${PREFIX}TcfApiReady`,
  ADSERVERREADY = `${PREFIX}QMAdserverReady`,
  SLOTREQUESTED = `${PREFIX}SlotRequested`,
  SLOTRESPONSERECEIVED = `${PREFIX}SlotResponseReceived`,
  SLOTONLOAD = `${PREFIX}SlotOnload`,
  SLOTRENDERENDED = `${PREFIX}SlotRenderEnded`,
}

export enum EPerformanceMeasure {
  HYDRATIONDURATION = `${PREFIX}HydrationDuration`,
  SLOTPROCESSDURATION = `${PREFIX}SlotTotalDuration`,
  SLOTBIDDINGDURATION = `${PREFIX}SlotResponseReceivedDuration`,
  SLOTSTARTLOADDURATION = `${PREFIX}SlotOnLoadDuration`,
  SLOTRENDERINGDURATION = `${PREFIX}SlotRenderEndedDuration`,
}
